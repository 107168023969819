import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from 'components/shared/Heading';
import ContentWrapper from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import contentBackground from 'assets/images/about2BG.png';
import ScrollableSection from 'react-update-url-on-scroll';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';
import { NavigationContext } from 'contexts/NavigationContext';

const Wrapper = styled.section`
  position: relative;
  z-index: 5;

  background-image: url(${contentBackground});
  background-position: right center;
  background-size: contain;
  background-repeat: no-repeat;
  padding-bottom: 220px;

  ${({ theme }) => theme.mq.md} {
    padding-bottom: 250px;
  }

  ${({ theme }) => theme.mq.xl} {
    background-position: right center;
    margin-top: -200px;
    padding-bottom: 0;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: auto;
  max-width: 550px;
  margin-bottom: -50px;

  ${({ theme }) => theme.mq.s} {
    margin-bottom: -200px;
  }

  ${({ theme }) => theme.mq.xl} {
    transform: none;
    margin-bottom: 0;
    padding-right: 100px;
    max-width: 100%;
  }
`;

const Content = styled.article`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 600px;

  ${({ theme }) => theme.mq.xl} {
    max-width: 750px;
    margin-left: initial;
  }
`;

const Description = styled.div`
  padding-top: 20px;
  width: 100%;
  max-width: 580px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mq.s} {
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ProspectWrapper = styled.div`
  position: relative;
  width: 100%;
  margin: 30px 0 20px;

  ${Button} {
    max-width: unset;
    width: fit-content;
    font-size: ${({ theme }) => theme.fontSize.s};
  }

  ${({ theme }) => theme.mq.s} {
    display: flex;
    flex-direction: column;

    ${Button} {
      margin: 0 auto 0 0;
    }
  }
`;

const ProspectHeading = styled.h4`
  margin-bottom: 20px;
  color: #000000;
  text-align: center;
  font-size: ${({ theme }) => theme.fontSize.md};
  font-weight: ${({ theme }) => theme.semiBold};

  ${({ theme }) => theme.mq.s} {
    text-align: left;
  }
`;

const StyledHeading = styled(Heading)`
  padding-left: 1rem;
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    text-align: left;
  }
`;

const About2 = ({ heading, description, prospect, image, imageAlt }) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    typeof window !== 'undefined' && (
      <Wrapper>
        <ScrollableSection name={`${initialSearchParam}#o-inwestycji  `}>
          <InnerWrapper>
            <ImageWrapper>
              <LazyImage src={image} alt={imageAlt} cover />
            </ImageWrapper>
            <ContentWrapper>
              <Content>
                <StyledHeading
                  secondary
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
                <Description>
                  <Text dangerouslySetInnerHTML={{ __html: description }} />
                </Description>
                {prospect.aboutProspectAttachment &&
                  prospect.aboutProspectAttachment.link && (
                    <ProspectWrapper>
                      <ProspectHeading>
                        {prospect.aboutProspectTitle}
                      </ProspectHeading>
                      <Button
                        as="a"
                        href={prospect.aboutProspectAttachment.link}
                        target="_blank"
                        rel="noreferrer"
                        onClick={() => {
                          emitMatomoEvent({
                            event: eventsNames.click_prospekt_informacyjny,
                          });
                        }}
                      >
                        {prospect.aboutProspectText}
                      </Button>
                    </ProspectWrapper>
                  )}
              </Content>
            </ContentWrapper>
          </InnerWrapper>
        </ScrollableSection>
      </Wrapper>
    )
  );
};

About2.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  prospect: PropTypes.object,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
};

export default About2;
