import styled from 'styled-components';

const Text = styled.p`
  color: ${({ theme }) => theme.colors.dark};
  font-weight: ${({ theme }) => theme.medium};
  font-size: 1.1rem;
  line-height: 1.7rem;
  text-align: ${({ align }) => align || 'center'};
  ${({ theme }) => theme.mq.s} {
    font-size: 1.4rem;
    line-height: 2.2rem;
    text-align: ${({ align }) => align || 'justify'};
  }
  ${({ theme }) => theme.mq.lg} {
    font-size: 1.6rem;
    line-height: 2.4rem;
  }
`;

export default Text;
