import React, { useRef, useState, useEffect } from 'react';
import styled, { css } from 'styled-components';
import { Formik, Form } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Button from 'components/shared/Button';
import useFormData from 'hooks/useFormData';
import getFormParams from 'browser/utils/getFormParams';
import getCookie from 'browser/utils/getCookie';
import SuccessInfo from 'components/shared/SuccessInfo';
import emitMatomoEvent, {
  eventsNames,
  getVisitorId,
} from 'utils/emitMatomoEvent';

const StyledForm = styled(Form)`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  overflow: visible;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.xl} {
    max-width: 750px;
  }
`;

const InputItem = styled.div`
  position: relative;
  margin: 8px 0;
`;

const InputLabel = styled.label`
  position: absolute;
  top: -8px;
  left: 5px;
  font-size: 1.2rem;
  opacity: 0;
  transition: 0.2s;
  ${({ error }) =>
    error &&
    css`
      opacity: 1;
      color: ${({ theme }) => theme.colors.red100};
    `}
`;

const Input = styled.input`
  position: relative;
  width: 100%;
  padding: 12px 5px;
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.4rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.regular};
  overflow: visible;
  transition: 0.3s;
  background: transparent;
  border-bottom: 1px solid ${({ theme }) => theme.colors.white};
  &:focus {
    border-color: ${({ theme }) => theme.colors.pink} !important;
    ::placeholder {
      opacity: 0;
    }
  }
  &:focus + ${InputLabel} {
    opacity: 1;
  }
  ${({ theme }) => theme.mq.s} {
    padding: 15px 5px;
    font-size: 1.8rem;
  }
  ::placeholder {
    color: ${({ theme }) => theme.colors.white};
    font-family: ${({ theme }) => theme.fonts.subFont};
    opacity: 0.8;
  }
  ${({ error }) =>
    error &&
    css`
      border-color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ valid }) =>
    valid &&
    css`
      border-color: ${({ theme }) => theme.colors.white};
    `}
`;

const Textarea = styled(Input)`
  flex: 1;
  resize: none;
  font-family: inherit;
  font-weight: ${({ theme }) => theme.light};
  min-height: 120px;
`;

const Label = styled.label`
  display: flex;
  align-items: center;
  min-height: 12px;
  position: relative;
  padding-left: 30px;
  margin: 6px 0;
  cursor: pointer;
  font-weight: ${({ theme }) => theme.light};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fonts.subFont};
  user-select: none;
  line-height: 18px;
  ${({ theme }) => theme.mq.xs} {
    font-size: ${({ theme }) => theme.fontSize.s};
  }
  ${({ theme }) => theme.mq.s} {
    margin: 10px 0;
  }
`;

const LabelBtn = styled.button`
  background: transparent;

  color: ${({ theme }) => theme.colors.white};
  font-weight: ${({ theme }) => theme.bold};
  font-size: ${({ theme }) => theme.fontSize.xs};
  font-family: ${({ theme }) => theme.fonts.mainFont};
  text-transform: uppercase;
  cursor: pointer;
  align-self: flex-start;
  padding: 2px 0 2px 10px;
  margin-left: auto;
`;

const LabelText = styled.p`
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: left;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  a {
    color: ${({ theme }) => theme.colors.blue100};
    text-decoration: none;
    transition: 0.2s;
    &:hover {
      color: ${({ theme }) => theme.colors.blue};
    }
  }
  ${({ expanded }) =>
    expanded &&
    css`
      display: block;
      text-overflow: clip;
      -webkit-line-clamp: none;
    `}
`;

const FormHeading = styled.h3`
  text-transform: uppercase;
  text-align: center;
  font-size: 2.2rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.colors.pink};
  padding: 15px 0;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.8rem;
    text-align: left;
  }
`;

const FormSubheading = styled(FormHeading)`
  font-size: 2rem;
  text-align: center;
  margin-top: -2rem;
  color: ${({ theme }) => theme.colors.white};
  ${({ theme }) => theme.mq.s} {
    font-size: 2.6rem;
    text-align: left;
  }
`;

const Checkmark = styled.span`
  position: absolute;
  top: 3px;
  left: 0;
  height: 12px;
  width: 12px;
  background: ${({ theme }) => theme.colors.white};
  border: 1px solid;
  border-color: ${({ theme, error }) =>
    error ? theme.colors.red : theme.colors.white};
  &:after {
    content: '';
    position: absolute;
    display: none;
    left: 1px;
    top: 1px;
    width: 8px;
    height: 8px;
    background: ${({ theme }) => theme.colors.pink};
  }
`;

const Checkbox = styled.input`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
  &:checked ~ ${Checkmark}:after {
    display: block;
  }
`;

const FormFooter = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
  font-weight: ${({ theme }) => theme.light};
  & > button {
    margin: 0 auto;
  }
  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    justify-content: space-between;
    & > button {
      margin: 0 auto 0 0;
    }
  }
`;

const ErrorsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  order: -1;
  ${({ theme }) => theme.mq.md} {
    order: 1;
  }
  & > span {
    margin-bottom: 8px;
    &:last-child {
      margin-bottom: 25px;
    }
  }
`;

const ErrorMessage = styled.span`
  display: flex;
  font-size: ${({ theme }) => theme.fontSize.m};
  color: ${({ theme }) => theme.colors.red100};
  line-height: 18px;
`;

const MessageContent = styled(ErrorMessage)`
  opacity: ${({ color }) => (color ? '1' : '0')};
  visibility: ${({ color }) => (color ? 'visible' : 'hidden')};
  ${({ color }) =>
    color === 'red' &&
    css`
      color: ${({ theme }) => theme.colors.red100};
    `}
  ${({ color }) =>
    color === 'green' &&
    css`
      color: ${({ theme }) => theme.colors.green};
      &:before {
        display: none;
      }
    `}
`;

const ContactForm = ({ customID, flat, modalMessage }) => {
  const [submitSuccess, setSubmitSuccess] = useState(false);
  const [submitClicked, setSubmitClicked] = useState(false);
  const [expandedTerms, setExpandedTerms] = useState([]);
  const [formMessage, setFormMessage] = useState({ text: '', color: '' });
  const formikRef = useRef();
  const [fillEventStarted, setFillEventStarted] = useState(false);
  const [fillEventEmitted, setFillEventEmitted] = useState(false);

  useEffect(() => {
    if (fillEventStarted && fillEventEmitted) {
      emitMatomoEvent({
        event: eventsNames.formularz_dol_wypelnianie_start,
      });
    }
  }, [fillEventStarted, fillEventEmitted]);

  useEffect(() => {
    setFillEventStarted(false);
    setFillEventEmitted(false);
  }, []);

  /* eslint-disable consistent-return */
  /* eslint-disable no-param-reassign */

  useEffect(() => {
    if (formikRef.current) {
      formikRef.current.resetForm();
      formikRef.current.setFieldValue('message', modalMessage);
    }
  }, [modalMessage]);

  const contactSchema = Yup.object().shape({
    name: Yup.string().required('Proszę o podanie imienia'),
    email: Yup.string()
      .email('Adres email jest nieprawidłowy')
      .required('Proszę o podanie adresu email'),
    phone: Yup.string()
      .min(9, 'Numer telefonu jest nieprawidłowy')
      .matches(
        /^([+]?[\s0-9]+)?(\d{3}|[(]?[0-9]+[)])?([-]?[\s]?[0-9])+$/i,
        'Numer telefonu jest nieprawidłowy'
      )
      .required('Proszę podać numer telefonu'),
    agree1: Yup.bool().oneOf([true], 'Proszę o zaznaczenie zgód'),
  });

  const { formAgree, formSubmit } = useFormData();

  const terms = formAgree.map(
    ({
      formAgreeName,
      formAgreeRequired,
      formAgreeContent,
      formAgreeYlclass,
      formAgreeYlname,
    }) => ({
      name: formAgreeName,
      content: formAgreeContent,
      required: formAgreeRequired,
      YLClass: formAgreeYlclass,
      YLName: formAgreeYlname,
    })
  );

  const formTerms = terms.reduce((obj, item) => {
    obj[item.name] = false;
    return obj;
  }, {});

  const handleCheckbox = name => {
    if (expandedTerms.includes(name)) {
      setExpandedTerms(expandedTerms.filter(term => term !== name));
    } else setExpandedTerms([...expandedTerms, name]);
  };

  const handleReset = () => {
    setSubmitSuccess(false);
    setSubmitClicked(false);
    setFormMessage({ text: '', color: '' });
  };

  return (
    <Formik
      innerRef={formikRef}
      initialValues={{
        name: '',
        email: '',
        phone: '',
        message: modalMessage,
        acceptAll: false,
        ...formTerms,
      }}
      validationSchema={contactSchema}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        const sendEmail = async () => {
          const {
            name,
            email,
            phone,
            message,
            agree1,
            agree2,
            agree3,
            agree4,
          } = values;

          let params = {
            name,
            agree1,
            agree2,
            agree3,
            agree4,
            url: window.location.href,
          };
          email ? (params = { ...params, email }) : params;
          phone ? (params = { ...params, phone }) : params;
          message ? (params = { ...params, message }) : params;

          if (terms.some(term => term.required && values[term.name] === false))
            return setSubmitting(false);
          try {
            setSubmitting(true);
            const { data } = await axios.get(
              `https://grzybowska37.indigital.pl/inc/mailer.php`,
              {
                params: {
                  ...params,
                  source: flat ? `Strona główna - ${flat}` : 'Strona główna',
                },
              }
            );

            if (window.Monitor) window.Monitor.TrackForm('contactForm', 3, 3);

            let visId = getCookie('m_vis_id');

            if (!visId) {
              const savedVisitorId = getVisitorId();
              visId = savedVisitorId;
            }

            const CRMFormParams = {
              ...getFormParams(params),
              leadID: data.leadid,
              visitorID: visId,
              form_name: 'lp-form-kontakt',
              id_investment: '25',
            };
            // console.log(CRMFormParams);
            const { data: CRMData } = await axios.post(
              `https://api.matexipolska.pl/export/api_makeform/?mode=json`,
              {
                ...CRMFormParams,
              }
            );

            if (window.dataLayer) {
              window.dataLayer.push({
                event: 'formularz',
                mieszkanie: flat || '',
              });
            }
            if (window._mtm) {
              window._mtm.push({
                event: 'formularz',
                mieszkanie: flat || '',
              });
            }

            emitMatomoEvent({
              event: eventsNames.formularz_dol_wyslany,
              lead_id: data.leadid,
            });

            setSubmitting(false);
            setFormMessage({
              text: data.message,
              color: 'green',
            });

            setSubmitSuccess(true);

            setTimeout(() => {
              handleReset();
              resetForm();
            }, 10000);
          } catch (err) {
            console.log(err);
            emitMatomoEvent({
              event: eventsNames.formularz_dol_blad_wysylki,
            });
            setSubmitting(false);
            setFormMessage({
              text: 'Coś poszło nie tak!',
              color: 'red',
            });

            setTimeout(() => {
              setFormMessage({ text: '', color: '' });
            }, 2500);
          }
        };
        sendEmail();
      }}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        setErrors,
        setFieldValue,
        isSubmitting,
        resetForm,
      }) => (
        <StyledForm
          method="post"
          onSubmit={handleSubmit}
          autoComplete="off"
          id={flat ? 'contact-form-flat' : `contactForm`}
        >
          {submitSuccess ? (
            <SuccessInfo
              onClick={() => {
                handleReset();
                resetForm();
              }}
              contactForm
              whiteText
            />
          ) : (
            <>
              <FormHeading>Napisz do nas -</FormHeading>
              <FormSubheading as="h4">
                Opowiemy Ci o inwestycji już dziś
              </FormSubheading>
              <InputItem>
                <Input
                  id={`${customID}name`}
                  name="name"
                  className="yl-name"
                  placeholder="Imię*"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  valid={touched.name && !errors.name}
                  error={submitClicked && errors.name && touched.name}
                  value={values.name}
                  touched={touched.name}
                  errors={errors.name}
                />
                <InputLabel
                  valid={touched.name && !errors.name}
                  error={submitClicked && errors.name && touched.name}
                  htmlFor={`${customID}name`}
                >
                  {(submitClicked &&
                    errors.name &&
                    touched.name &&
                    errors.name) ||
                    'Imię*'}
                </InputLabel>
              </InputItem>
              <InputItem>
                <Input
                  id={`${customID}email`}
                  name="email"
                  className="yl-email"
                  placeholder="Adres email*"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  valid={touched.email && !errors.email}
                  error={submitClicked && errors.email && touched.email}
                  value={values.email}
                  touched={touched.email}
                  errors={errors.email}
                />
                <InputLabel
                  valid={touched.email && !errors.email}
                  error={submitClicked && errors.email && touched.email}
                  htmlFor={`${customID}email`}
                >
                  {(submitClicked &&
                    errors.email &&
                    touched.email &&
                    errors.email) ||
                    'Adres email*'}
                </InputLabel>
              </InputItem>
              <InputItem>
                <Input
                  id={`${customID}phone`}
                  name="phone"
                  className="yl-phone"
                  placeholder="Telefon*"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  valid={touched.phone && !errors.phone}
                  error={submitClicked && errors.phone && touched.phone}
                  value={values.phone}
                  touched={touched.phone}
                  errors={errors.phone}
                />
                <InputLabel
                  valid={touched.phone && !errors.phone}
                  error={submitClicked && errors.phone && touched.phone}
                  htmlFor={`${customID}name`}
                >
                  {(submitClicked &&
                    errors.phone &&
                    touched.phone &&
                    errors.phone) ||
                    'Telefon*'}
                </InputLabel>
              </InputItem>
              {modalMessage && modalMessage.length && (
                <Textarea
                  as="textarea"
                  id={`${customID}message`}
                  name="message"
                  className="yl-message"
                  placeholder="Wiadomość"
                  onChange={handleChange}
                  onKeyDown={() => {
                    if (!fillEventStarted && !fillEventEmitted) {
                      setFillEventStarted(true);
                      setFillEventEmitted(true);
                    }
                  }}
                  onBlur={handleBlur}
                  error={submitClicked && errors.message && touched.message}
                  value={values.message}
                  touched={touched.message}
                  errors={errors.message}
                />
              )}

              {terms.length !== 0 && (
                <Label htmlFor={`${customID}acceptAll`}>
                  Zaznacz wszystkie zgody
                  <Checkbox
                    id={`${customID}acceptAll`}
                    name="acceptAll"
                    type="checkbox"
                    onChange={() => {
                      if (!terms.every(term => values[term.name] === true)) {
                        setFieldValue('acceptAll', true);
                        terms.forEach(term => setFieldValue(term.name, true));
                      } else {
                        setFieldValue('acceptAll', true);
                        terms.forEach(term => setFieldValue(term.name, false));
                      }
                    }}
                    checked={terms.every(term => values[term.name] === true)}
                  />
                  <Checkmark />
                </Label>
              )}
              {terms.map(({ name, content, required, YLClass, YLName }) => (
                <Label htmlFor={`${customID}${name}`} key={content}>
                  <LabelText expanded={expandedTerms.includes(name)}>
                    {content}
                  </LabelText>
                  <LabelBtn type="button" onClick={() => handleCheckbox(name)}>
                    {expandedTerms.includes(name) ? 'Mniej' : 'Więcej'}
                  </LabelBtn>
                  <Checkbox
                    id={`${customID}${name}`}
                    className={YLClass}
                    name={YLName}
                    value="1"
                    type="checkbox"
                    onChange={() => {
                      setFieldValue(name, !values[name]);
                      required && setErrors({ ...errors, agree1: '' });
                    }}
                    checked={values[name]}
                  />
                  <Checkmark
                    error={
                      submitClicked &&
                      errors.agree1 &&
                      required &&
                      values[name] === false
                    }
                  />
                </Label>
              ))}
              <FormFooter>
                <Button
                  id={`${customID}submit-button`}
                  name="submit-button"
                  submit
                  onClick={() => {
                    setSubmitClicked(true);
                    return false;
                  }}
                  disabled={isSubmitting}
                  type="submit"
                >
                  {isSubmitting ? '' : formSubmit}
                </Button>
                <ErrorsWrapper>
                  {submitClicked && errors.agree1 && (
                    <ErrorMessage>{errors.agree1}</ErrorMessage>
                  )}
                  {formMessage.text && (
                    <MessageContent color={formMessage.color}>
                      {formMessage.text}
                    </MessageContent>
                  )}
                </ErrorsWrapper>
              </FormFooter>
            </>
          )}
        </StyledForm>
      )}
    </Formik>
  );
};

ContactForm.defaultProps = {
  customID: '',
  modalMessage: '',
  flat: '',
};

export default ContactForm;
