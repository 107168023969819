import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Heading from 'components/shared/Heading';
import ContentWrapper from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Text from 'components/shared/Text';
import Icon from 'components/shared/Icon';
import Button from 'components/shared/Button';
import Gallery from 'components/Gallery/Gallery';
import clipBackground from 'assets/images/designBG.png';
import clipBackgroundMobile from 'assets/images/designBGMobile.png';
import phoneIcon from 'assets/icons/phone.png';
import email from 'assets/icons/mail.svg';
import deerPDF from 'assets/Ulotka Deer Design Matexi.pdf';
import ScrollableSection from 'react-update-url-on-scroll';
import { NavigationContext } from 'contexts/NavigationContext';

const Wrapper = styled.section`
  position: relative;
  margin-top: 50px;
  &:before {
    content: '';
    position: absolute;
    top: 10%;
    bottom: -25%;
    right: 0;
    width: 100%;
    z-index: 1;
    background: ${({ theme }) => theme.colors.dark100};
    background-image: url(${clipBackgroundMobile});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    clip-path: polygon(0 20%, 100% 0, 100% 90%, 0 80%);
    ${({ theme }) => theme.mq.s} {
      bottom: -30%;
      background-image: url(${clipBackground});
      clip-path: polygon(0 20%, 100% 0, 100% 83%, 0 73%);
    }
    ${({ theme }) => theme.mq.xl} {
      clip-path: polygon(0 20%, 100% 0, 100% 96%, 0 56%);
    }

    ${({ theme }) => theme.mq.xxl} {
      clip-path: polygon(0 20%, 100% 0, 100% 99%, 0 56%);
    }
  }
`;

const AboutWrapper = styled.div`
  position: relative;
  z-index: 4;
  ${({ theme }) => theme.mq.s} {
    margin-top: 100px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin-bottom: -50px;

  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 0;
    padding-right: 20px;
    max-width: 100%;
  }

  ${({ theme }) => theme.mq.xxl} {
    padding-right: 100px;
  }
`;

const GalleryWrapper = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: 0 10px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.md} {
    padding: 0 20px;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 0 30px;
    // max-width: 1100px;
  }
`;

const Content = styled.article`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 600px;

  ${({ marginTop }) =>
    marginTop &&
    css`
      margin-top: 30px;
    `}

  ${({ theme }) => theme.mq.xl} {
    max-width: 750px;
    margin-left: initial;
  }
`;

const Description = styled.div`
  padding-top: 10px;
  width: 100%;
  max-width: 580px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mq.s} {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
  color: ${({ theme }) => theme.colors.white};
`;

const Logo = styled.figure`
  display: block;
  width: 250px;
  max-width: 100%;
  margin: 10px auto;
  ${({ theme }) => theme.mq.s} {
    margin: 10px 0;
  }
  ${({ theme }) => theme.mq.md} {
    width: 300px;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.pink};
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    text-align: left;
  }
`;

const StyledText = styled(Text)`
  color: inherit;
`;

const StyledText2 = styled(Text)``;

const List = styled.ul`
  margin-top: 20px;
`;

const ListItem = styled(Text.withComponent('li'))`
  color: inherit;
  margin: 4px 0;
  font-weight: ${({ theme }) => theme.semiBold};
  a {
    color: ${({ theme }) => theme.colors.pink};
    transition: color ease 200ms;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.pink100};
    }
  }
`;

const PhoneButton = styled(Button.withComponent('a'))`
  width: 90%;
  max-width: initial;
  white-space: nowrap;
  font-size: 1.2rem;
  max-width: 250px;

  margin: 16px auto;
  padding: 8px 10px;

  ${({ theme }) => theme.mq.s} {
    font-size: 1.5rem;
    max-width: 300px;
    width: auto;
    margin: 16px 0;
    padding: 10px 12px;
  }
`;

const Services = styled.div`
  display: grid;
  margin-top: 20px;
  row-gap: 20px;
`;

const Service = styled.div``;

const ServiceTitle = styled(Text)`
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 12px;
  color: ${({ theme }) => theme.colors.white};

  ${({ theme }) => theme.mq.xl} {
    font-size: 16px;
  }
`;

const ServiceLabel = styled(Text)`
  display: flex;
  align-items: flex-start;
  margin-bottom: 5px;
  color: ${({ theme }) => theme.colors.white};

  &:before {
    content: '';
    display: block;
    width: 5px;
    height: 5px;
    border-radius: 50%;
    margin-right: 6px;
    background-color: ${({ theme }) => theme.colors.white};
    position: relative;
    top: 7px;
  }

  ${({ theme }) => theme.mq.s} {
    &:before {
      content: '';
      display: block;
      width: 6px;
      height: 6px;
    }
  }

  ${({ theme }) => theme.mq.xl} {
    font-size: 16px;
  }
`;

const Team = styled.div`
  display: grid;
  row-gap: 20px;
  margin-top: 20px;

  ${({ theme }) => theme.mq.xl} {
    grid-template-columns: 1fr 1fr;
  }
`;

const Person = styled.div`
  display: flex;
  flex-direction: column;

  ${Text} {
    color: ${({ theme }) => theme.colors.white};
  }

  .person-name {
    font-size: 16px;
    font-weight: 600;
  }
`;

const Link = styled.a`
  display: flex;
  align-items: center;
  margin-top: 7px;
  text-decoration: none;
  color: inherit;

  &:hover {
    ${Text} {
      color: ${({ theme }) => theme.colors.primary};
    }
  }
`;

const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
`;

const About4 = ({
  heading,
  description,
  text1,
  text2,
  phone,
  logo,
  logoAlt,
  image,
  imageAlt,
  // buttonText,
  // buttonPhone,
  offers,
  galleryImages,
  finishingProps,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  // const offersDOM = offers.map(el => (
  //   <ListItem key={el.name}>
  //     {el.name}
  //     {el.pdf && (
  //       <>
  //         – <a href={el.pdf}>(Pobierz katalog PDF)</a>
  //       </>
  //     )}
  //   </ListItem>
  // ));

  return (
    typeof window !== 'undefined' && (
      <ScrollableSection name={`${initialSearchParam}#galeria`}>
        <Wrapper id="galeria">
          <GalleryWrapper>
            <Gallery images={galleryImages} />
          </GalleryWrapper>

          <AboutWrapper>
            <InnerWrapper>
              <ImageWrapper>
                <LazyImage src={image} alt={imageAlt} cover />
              </ImageWrapper>
              <ContentWrapper>
                {/* PAKIETY WYKONCZENIOWE  */}
                <Content>
                  <StyledHeading
                    secondary
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                  <Logo>
                    <LazyImage src={logo} alt={logoAlt} cover />
                  </Logo>
                  <Description>
                    <StyledText
                      dangerouslySetInnerHTML={{ __html: description }}
                    />
                    <br />
                    <StyledText dangerouslySetInnerHTML={{ __html: text1 }} />
                    {/* <List>{offersDOM}</List> */}
                    {/* <PhoneButton href={`tel:${buttonPhone}` || '#'}>
                  {buttonText}
                </PhoneButton> */}
                    <PhoneButton href={`tel: ${phone}`}>
                      ZADZWOŃ {phone}
                    </PhoneButton>
                    <StyledText dangerouslySetInnerHTML={{ __html: text2 }} />
                  </Description>
                </Content>
                {/* PAKIETY WYKONCZENIOWE  */}

                {/* Z NAMI  */}
                {/* <Content marginTop>
                  <StyledHeading
                    as="h2"
                    secondary
                    dangerouslySetInnerHTML={{ __html: finishingProps.heading }}
                  />
                  <Logo>
                    <LazyImage
                      src={finishingProps.logo}
                      alt={finishingProps.logoAlt}
                    />
                  </Logo>
                  <Description>
                    <StyledText
                      dangerouslySetInnerHTML={{
                        __html: finishingProps.description,
                      }}
                    />
                  </Description>
                  {(finishingProps.basicServicesList ||
                    finishingProps.additionalServicesList) && (
                    <Services>
                      {finishingProps.basicServicesList && (
                        <Service>
                          <ServiceTitle
                            align="left"
                            dangerouslySetInnerHTML={{
                              __html: 'Usługi podstawowe',
                            }}
                          />
                          {finishingProps.basicServicesList.map(service => (
                            <ServiceLabel
                              align="left"
                              dangerouslySetInnerHTML={{ __html: service }}
                            />
                          ))}
                        </Service>
                      )}
                      {finishingProps.additionalServicesList && (
                        <Service>
                          <ServiceTitle
                            align="left"
                            dangerouslySetInnerHTML={{
                              __html: 'Usługi dodatkowe',
                            }}
                          />
                          {finishingProps.additionalServicesList.map(
                            service => (
                              <ServiceLabel
                                align="left"
                                dangerouslySetInnerHTML={{ __html: service }}
                              />
                            )
                          )}
                        </Service>
                      )}
                    </Services>
                  )}
                  {finishingProps.team && finishingProps.team.length > 0 && (
                    <Team>
                      {finishingProps.team.map(person => (
                        <Person key={person.name}>
                          <Text
                            className="person-name"
                            align="left"
                            dangerouslySetInnerHTML={{ __html: person.name }}
                          />
                          <Text
                            align="left"
                            dangerouslySetInnerHTML={{ __html: person.job }}
                          />
                          <Link href={`tel: ${person.phone}`}>
                            <IconWrapper>
                              <Icon src={phoneIcon} width={20} alt="phone" />
                            </IconWrapper>
                            <Text
                              dangerouslySetInnerHTML={{ __html: person.phone }}
                            />
                          </Link>
                          <Link href={`mailto: ${person.email}`}>
                            <IconWrapper>
                              <Icon src={email} width={20} alt="email" />
                            </IconWrapper>
                            <Text
                              dangerouslySetInnerHTML={{ __html: person.email }}
                            />
                          </Link>
                        </Person>
                      ))}
                    </Team>
                  )}
                </Content> */}
                {/* Z NAMI  */}
              </ContentWrapper>
            </InnerWrapper>
          </AboutWrapper>
        </Wrapper>
      </ScrollableSection>
    )
  );
};

About4.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  text1: PropTypes.string.isRequired,
  text2: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,

  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,

  logo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  logoAlt: PropTypes.string.isRequired,

  offers: PropTypes.array.isRequired,

  buttonText: PropTypes.string.isRequired,
  buttonPhone: PropTypes.string.isRequired,

  galleryImages: PropTypes.array.isRequired,

  finishingProps: PropTypes.object.isRequired,
};

About4.defaultProps = {
  offers: [],
};

export default About4;
