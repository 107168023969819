import React, { useContext } from 'react';
import styled from 'styled-components';
import Section from 'components/shared/Section';
import Browser from 'components/Flats/BrowserWrapper';
import LazyLoad from 'react-lazyload';
import ScrollableSection from 'react-update-url-on-scroll';
import { NavigationContext } from '../../contexts/NavigationContext';

const StyledSection = styled(Section)`
  padding-top: 0 !important;
  padding-bottom: 0;
  ${({ theme }) => theme.mq.lg} {
    padding-top: 60px !important;
  }
`;

const WrapperComponent =
  typeof window !== 'undefined' ? ScrollableSection : React.Fragment;

const Flats = () => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    <WrapperComponent name={`${initialSearchParam}#mieszkania`}>
      <StyledSection>
        <Browser />
      </StyledSection>
    </WrapperComponent>
  );
};

export default Flats;
