import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Content from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Icon from 'components/shared/Icon';
import Text from 'components/shared/Text';
import ContactForm from 'components/Contact/ContactForm';
import matexiLogo from 'assets/icons/matexi-big.svg';
import phoneIcon from 'assets/icons/phone.png';
import mailIcon from 'assets/icons/mail.svg';
import useMedia from 'hooks/useMedia';
import pinIcon from 'assets/icons/pin.svg';
import useIsClipPathSupported from 'hooks/useIsClipPathSupported';
import ScrollableSection from 'react-update-url-on-scroll';
import emitMatomoEvent, { eventsNames } from 'utils/emitMatomoEvent';
import { NavigationContext } from 'contexts/NavigationContext';
import { Link } from 'gatsby';

const Wrapper = styled.section`
  position: relative;
  z-index: 3;
  height: auto;
  @media (max-width: 1200px) {
    padding-top: 50px;
  }
  ${({ clipPath }) =>
    clipPath &&
    css`
      margin-top: 80px;

      ${({ theme }) => theme.mq.md} {
        height: 1700px;
      }

      ${({ theme }) => theme.mq.xl} {
        height: 1800px;
      }

      &:before {
        position: absolute;
        bottom: 0;
        right: 0;
        width: 100%;
        height: 980px;
        z-index: 4;
        background: ${({ theme }) => theme.colors.dark100};
        clip-path: polygon(30% 35%, 110% 0, 100% 100%, 0 100%, 0 26%);
        ${({ theme }) => theme.mq.md} {
          content: '';
        }
        ${({ theme }) => theme.mq.lg} {
          /* clip-path: polygon(30% 35%, 110% 0, 100% 100%, 0 100%, 0 26%); */
        }

        ${({ theme }) => theme.mq.xl} {
          height: 1200px;
        }
      }
    `}
`;

const ImageWrapper = styled.div`
  ${({ clipPath }) =>
    clipPath &&
    css`
      position: absolute;
      z-index: 5;
      top: -6%;
      right: 0;
      width: 65vw !important;
      height: auto !important;
      @media (max-width: 380px) {
        top: -60px;
      }
      ${({ theme }) => theme.mq.s} {
        top: -9%;
        width: 45vw !important;
        height: auto !important;
      }

      ${({ theme }) => theme.mq.lg} {
        width: 40vw !important;
      }
      ${({ theme }) => theme.mq.xl} {
        top: -5%;
      }
    `}
`;

const StyledContent = styled(Content)`
  padding-top: 80px;
  padding-bottom: 80px;
  max-width: 1200px;
  display: flex;
  flex-direction: column-reverse;
  align-items: center;
  justify-content: space-between;
  ${({ clipPath }) =>
    clipPath &&
    css`
      padding-top: 120px;
      padding-bottom: 20px;
      ${({ theme }) => theme.mq.s} {
        padding-top: 200px;
        padding-bottom: 0;
      }
    `}

  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    align-items: flex-end;
  }
`;

const Footer = styled.footer`
  background: ${({ theme }) => theme.colors.dark100};
  ${({ clipPath }) =>
    clipPath &&
    css`
      position: relative;
      padding-top: 20px;
      padding-bottom: 20px;
      width: 100%;
      background: none;
      &:before {
        content: '';
        position: absolute;

        top: 0;
        bottom: 0;
        width: 100%;

        z-index: 4;
        background: ${({ theme }) => theme.colors.dark100};
        clip-path: polygon(50% 110px, 100% 0, 100% 100%, 0 100%, 0 0);
        ${({ theme }) => theme.mq.xs} {
          clip-path: polygon(50% 10%, 100% 0, 100% 100%, 0 100%, 0 0);
        }
      }
      ${({ theme }) => theme.mq.xs} {
        padding-top: 100px;
      }
      ${({ theme }) => theme.mq.md} {
        &:before {
          content: none;
        }
        padding-bottom: 0;
        position: absolute;
        bottom: 50px;
        left: 0;
        z-index: 6;
        background: transparent;
      }
      ${({ theme }) => theme.mq.xl} {
        bottom: 120px;
      }
    `}
`;

const Column = styled.div`
  position: relative;
  z-index: 5;
  display: flex;
  flex-direction: column;
`;

const ContactColumn = styled(Column)`
  position: relative;
  padding: 0 20px;
  margin-bottom: 3rem;
  ${({ theme }) => theme.mq.md} {
    padding: 0;
    margin-bottom: auto;
    margin-left: 10vw;
  }
`;

const LogoWrapper = styled.a`
  display: block;
  width: 180px;
  margin: 10px auto;
  ${({ theme }) => theme.mq.s} {
    margin-top: 10px;
    margin-left: 10px;
  }
  ${({ theme }) => theme.mq.md} {
    width: 230px;
  }
`;

const NotusLogoWrapper = styled.figure`
  display: block;
  width: 200px;
  margin: 100px auto 10px;

  ${({ theme }) => theme.mq.s} {
    margin: 0 0 10px;
  }

  ${({ theme }) => theme.mq.md} {
    width: 250px;
  }
`;

const Heading = styled.h3`
  text-transform: uppercase;
  text-align: center;
  font-size: 2.2rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.bold};
  color: ${({ theme }) => theme.colors.pink};
  padding: 15px 0;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.8rem;
    text-align: left;
  }
`;

const Detail = styled.p`
  color: ${({ theme }) => theme.colors.white};
  font-size: 1.7rem;
  font-weight: ${({ theme }) => theme.semiBold};
  line-height: 3.6rem;
  ${({ theme }) => theme.mq.s} {
    font-size: 1.5rem;
    line-height: 3rem;
  }
`;

const StyledDetail = styled(Detail)`
  display: flex;
  align-items: center;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;
  white-space: nowrap;

  & > div {
    margin-right: 10px;
  }
  font-size: 12px;
  ${({ theme }) => theme.mq.s} {
    margin-top: 10px;
    font-size: 16px;
  }
`;

const Description = styled.div`
  width: 100%;
  max-width: 700px;
  p {
    margin-bottom: 1rem;
  }
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mq.s} {
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const ContentWrapper = styled.div`
  ${({ clipPath }) =>
    !clipPath &&
    css`
      display: flex;
      flex-direction: column;
      padding: 30px 0;
      ${({ theme }) => theme.mq.xl} {
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
      }
    `}
`;

const Persons = styled.div`
  margin-top: 40px;
  max-width: 700px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
  flex-wrap: wrap;
  ${({ theme }) => theme.mq.s} {
    flex-direction: row;
  }
`;

const Person = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;

  padding: 12px 0;
  ${({ theme }) => theme.mq.s} {
    display: block;
  }
`;

const PersonSeparator = styled.div`
  height: 1px;
  width: 220px;
  background: ${({ theme }) => theme.colors.pink};
  ${({ theme }) => theme.mq.s} {
    height: 180px;
    width: 2px;
  }
`;

const PersonName = styled(Heading)`
  padding-bottom: 0;
  font-size: 2rem;
  ${({ theme }) => theme.mq.s} {
    font-size: 2.2rem;
  }
`;

const PersonJob = styled(Text)`
  font-size: 12px;
  font-family: ${({ theme }) => theme.fonts.subFont};
  padding: 0 0 8px;
  ${({ theme }) => theme.mq.s} {
    padding: 0 0 4px;
    font-size: 14px;
  }
`;

const PersonContact = styled.div`
  font-family: ${({ theme }) => theme.fonts.subFont};
  display: flex;
  align-items: center;
  font-size: 14px;
  margin: 4px 0;

  span,
  a {
    display: block;
    color: inherit;
    text-decoration: none;
    margin-left: 8px;
    font-weight: ${({ theme }) => theme.semiBold};
  }

  ${({ theme }) => theme.mq.s} {
    margin: 8px 0;
    font-size: 16px;
  }
`;

const Text1 = styled(Text)`
  margin-bottom: 10px;
  font-weight: 700; ;
`;
const Text2 = styled(Text)`
  font-style: italic;
  margin-top: 10px;
  ${({ theme }) => theme.mq.md} {
    max-width: 400px;
  }

  ${({ theme }) => theme.mq.xl} {
    max-width: 600px;
  }
`;
const OpeningHoursLabels = styled.div`
  display: grid;
  row-gap: 3px;
`;

const InfoWrapper = styled.div`
  color: #fff;
  font-size: 16px;
  font-family: ${({ theme }) => theme.fonts.subFont};
  font-weight: ${({ theme }) => theme.semiBold};
  margin: 30px 0 15px;
  line-height: normal;
  span {
    margin-bottom: 15px;
    display: block;
  }
  p {
    font-size: 15px;
  }
  p + p {
    margin-top: 6px;
  }
  .spaceTop {
    margin-top: 15px;
  }
`;

const PDFButton = styled.a`
  display: flex;
  justify-content: center;
  align-items: center;
  line-height: 2rem;
  font-family: ${({ theme }) => theme.fonts.subFont};
  background: #e60060;
  border: 2px solid #e60060;
  transition: 0.3s;
  color: #fff;
  text-decoration: none;
  font-weight: 700;
  text-align: center;
  padding: 12px 40px;
  &:hover {
    background: transparent;
  }
  margin-top: 30px;
  margin-bottom: 30px;
`;

const PoliciesLinks = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 12px;
  margin-top: 8px;
  margin-bottom: 8px;

  ${({ theme }) => theme.mq.md} {
    flex-direction: row;
    gap: 16px;
  }
`;

const PrivacyPolicyLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.white};
  text-decoration: none;

  ${({ theme }) => theme.mq.s} {
    justify-content: flex-start;
    font-size: 15px;
  }
`;

const Contact = ({
  description,
  image,
  imageAlt,
  email,
  contactData,
  phone,
  logo,
  logoAlt,
  persons,
  infos,
  hours,
  promoPdf,
  creditProps,
}) => {
  const clipPath = useIsClipPathSupported();
  const matchesS = useMedia('(min-width: 500px)');

  const { initialSearchParam } = useContext(NavigationContext);

  const personsDOM = persons.map((el, index) => (
    <React.Fragment key={index}>
      <Person>
        <PersonName>{el.name}</PersonName>
        <PersonJob>{el.job}</PersonJob>
        <PersonContact>
          <Icon src={phoneIcon} alt="phone" size="18" />
          <a href={`tel:${el.phone}`}>{el.phone}</a>
        </PersonContact>
        <PersonContact>
          <Icon src={mailIcon} alt="email" size="18" />
          <a href={`mailto:${el.mail}`}>{el.mail}</a>
        </PersonContact>
      </Person>
      {index === 0 && <PersonSeparator />}
      {!matchesS && index === 1 && <PersonSeparator />}
    </React.Fragment>
  ));

  return (
    typeof window !== 'undefined' && (
      <ScrollableSection name={`${initialSearchParam}#kontakt`}>
        <Wrapper clipPath={clipPath}>
          <ImageWrapper clipPath>
            <LazyImage src={image} alt={imageAlt} cover />
          </ImageWrapper>
          <ContentWrapper>
            <Content>
              <NotusLogoWrapper>
                <LazyImage src={logo} alt={logoAlt} />
              </NotusLogoWrapper>
              <Description>
                <Text
                  as="div"
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              </Description>
              <Persons>{personsDOM}</Persons>
              {creditProps.text1 && (
                <Text1
                  dangerouslySetInnerHTML={{ __html: creditProps.text1 }}
                />
              )}

              {creditProps.openingHoursLabels &&
                creditProps.openingHoursLabels.length > 0 && (
                  <OpeningHoursLabels>
                    {creditProps.openingHoursLabels.map(label => (
                      <Text
                        key={label}
                        dangerouslySetInnerHTML={{ __html: label }}
                      />
                    ))}
                  </OpeningHoursLabels>
                )}
              {creditProps.text2 && (
                <Text2
                  dangerouslySetInnerHTML={{ __html: creditProps.text2 }}
                />
              )}
            </Content>
          </ContentWrapper>
          <Footer clipPath={clipPath}>
            <StyledContent clipPath={clipPath}>
              <Column id="address" as="address">
                {matchesS && <Heading>Kontakt</Heading>}

                <StyledDetail as="div">
                  <Icon src={pinIcon} alt="pin" size="20" />
                  <span
                    dangerouslySetInnerHTML={{
                      __html: contactData.replace('<br/>', ''),
                    }}
                  />
                </StyledDetail>
                <StyledDetail
                  as="a"
                  href={`tel: ${phone}`}
                  onClick={() =>
                    emitMatomoEvent({
                      event: eventsNames.click_telefon,
                      tel: phone,
                    })
                  }
                >
                  <Icon src={phoneIcon} alt="phone" size="20" />
                  {phone}
                </StyledDetail>
                {/* <StyledDetail as="a" href={`mailto: ${email}`}>
                  <Icon src={mailIcon} alt="email" size="20" />
                  {email}
                </StyledDetail> */}
                <InfoWrapper>
                  <span>Godziny otwarcia:</span>
                  {hours.map(el => (
                    <p key={el} dangerouslySetInnerHTML={{ __html: el }} />
                  ))}
                  {infos.map((info, index) => (
                    <span
                      className="spaceTop"
                      key={index}
                      dangerouslySetInnerHTML={{ __html: info }}
                    />
                  ))}
                  {promoPdf.pdfLink && promoPdf.pdfText && (
                    <PDFButton
                      href={promoPdf.pdfLink}
                      target="_blank"
                      dangerouslySetInnerHTML={{
                        __html: promoPdf.pdfText,
                      }}
                    />
                  )}
                </InfoWrapper>

                <LogoWrapper
                  href="https://matexipolska.pl/"
                  target="_blank"
                  rel="noopener noreferrer nofollow"
                >
                  <Icon full src={matexiLogo} alt="matexi" />
                </LogoWrapper>
                <PoliciesLinks>
                  <PrivacyPolicyLink to="/polityka-prywatnosci">
                    Polityka prywatności
                  </PrivacyPolicyLink>
                  <PrivacyPolicyLink to="/polityka-cookies">
                    Polityka cookies
                  </PrivacyPolicyLink>
                </PoliciesLinks>
              </Column>
              <ContactColumn id="kontakt">
                <ContactForm customID="footer" />
              </ContactColumn>
            </StyledContent>
          </Footer>
        </Wrapper>
      </ScrollableSection>
    )
  );
};

Contact.propTypes = {
  description: PropTypes.string.isRequired,
  contactData: PropTypes.string.isRequired,
  email: PropTypes.string.isRequired,
  phone: PropTypes.string.isRequired,
  logo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  logoAlt: PropTypes.string.isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  infos: PropTypes.arrayOf(PropTypes.string).isRequired,
  hours: PropTypes.arrayOf(PropTypes.string).isRequired,
  imageAlt: PropTypes.string.isRequired,
  persons: PropTypes.array,
  promoPdf: PropTypes.object,
  creditProps: PropTypes.object.isRequired,
};

Contact.defaultProps = {
  persons: [],
  promoPdf: {},
};

export default Contact;
