import { graphql, useStaticQuery } from 'gatsby';

const useFormData = () => {
  const {
    wpgraphql: {
      page: {
        PageHome: { formAgree, formSubmit },
      },
    },
  } = useStaticQuery(graphql`
    query queryForm {
      wpgraphql {
        page(id: "strona-glowna", idType: URI) {
          PageHome {
            formAgree {
              formAgreeContent
              formAgreeRequired
              formAgreeName
              formAgreeYlclass
              formAgreeYlname
            }
            formSubmit
          }
        }
      }
    }
  `);

  return { formAgree, formSubmit };
};

export default useFormData;
