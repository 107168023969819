import React from 'react';
import styled from 'styled-components';
import ContentWrapper from 'components/shared/Content';
import AskForm from './AskForm';

const StyledContentWrapper = styled(ContentWrapper)`
  margin: 60px 0;

  ${({ theme }) => theme.mq.s} {
    display: flex;
    flex-direction: column;
  }

  ${({ theme }) => theme.mq.md} {
    padding-left: calc(8% + 30px);
  }

  ${({ theme }) => theme.mq.xl} {
    padding-left: 30px;
  }

  @media (min-width: 1600px) {
    padding: 0 calc(5vw + 30px) 0;
  }
`;

const FormWrapper = styled.div`
  position: relative;
  width: 100%;
  max-width: 580px;
  z-index: 1;

  ${({ theme }) => theme.mq.xl} {
    width: 580px;
    min-width: 580px;
  }
`;

const AskAboutFlat = () => {
  return (
    <StyledContentWrapper>
      <FormWrapper>
        <AskForm customID="formTop" />
      </FormWrapper>
    </StyledContentWrapper>
  );
};

export default AskAboutFlat;
