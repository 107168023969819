/* eslint-disable react/prop-types */
import React, { useState, useEffect, useRef } from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';

import Icon from 'components/shared/Icon';

import scrollToSection from 'utils/scrollToSection';
import useOutsideClick from 'hooks/useOutsideClick';

import closeIcon from 'assets/icons/close.svg';

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: rgba(255, 255, 255, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1001;
  opacity: 0;
  visibility: hidden;
  transition: 0.3s;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
    `}
`;

const Wrapper = styled.div`
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -35%);
  visibility: hidden;
  opacity: 0;
  transition: 0.35s ease;
  cursor: pointer;
  ${({ visible }) =>
    visible &&
    css`
      opacity: 1;
      visibility: visible;
      transform: translate(-50%, -50%);
    `}
`;

const InnerWrapper = styled.div`
  position: relative;
`;

const CloseButton = styled.button`
  background: transparent;
  padding: 0;
  margin: 0;
  position: absolute;
  top: -35px;
  right: 0;
  width: 20px;
  height: 20px;
  z-index: 1;
  cursor: pointer;
  ${({ theme }) => theme.mq.md} {
    top: -34px;
    right: -34px;
    width: 25px;
    height: 25px;
  }
`;

const IconWrapper = styled.div`
  position: relative;

  & > img {
    max-width: 90vw;
    max-height: 50vh;
  }

  ${({ theme }) => theme.mq.md} {
    & > img {
      max-width: 640px;
    }
  }

  ${({ theme }) => theme.mq.lg} {
    & > img {
      max-height: 60vh;
    }
  }

  ${({ theme }) => theme.mq.xl} {
    & > img {
      max-width: 740px;
      max-height: 75vh;
    }
  }

  ${({ theme }) => theme.mq.xxl} {
    & > img {
      max-width: 820px;
    }
  }
`;

const PopupLink = styled(Link)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1;
`;

const Popup = ({ image, link }) => {
  if (!image.src) return null;

  const [visible, setVisible] = useState(false);

  const KEY = image.alt || 'matexi-ad-popup';

  const closePopup = () => {
    sessionStorage.setItem('promoPopupClosed', true);
    setVisible(false);
  };

  useEffect(() => {
    if (!sessionStorage.getItem('promoPopupClosed')) {
      setTimeout(() => {
        setVisible(true);
      }, 200);
    }
  }, []);

  const popupRef = useRef(null);
  useOutsideClick(popupRef, closePopup);

  return (
    <Overlay visible={visible}>
      <Wrapper ref={popupRef} visible={visible}>
        <InnerWrapper>
          <CloseButton
            onClick={() => {
              closePopup();
            }}
          >
            <Icon src={closeIcon} alt="" full />
          </CloseButton>
          <IconWrapper
            onClick={() => {
              closePopup();
              scrollToSection('#mieszkania');
            }}
          >
            <img src={image.src} alt={KEY} auto />
            {link && <PopupLink to={link} />}
          </IconWrapper>
        </InnerWrapper>
      </Wrapper>
    </Overlay>
  );
};

export default Popup;
