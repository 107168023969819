import React, { useState } from 'react';
import styled, { css } from 'styled-components';
import ImageGallery from 'react-image-gallery';
import PropTypes from 'prop-types';
import useMedia from 'hooks/useMedia';
import Heading from 'components/shared/Heading';
import Icon from 'components/shared/Icon';
import LazyImage from 'components/shared/LazyImage.js';

import 'react-image-gallery/styles/css/image-gallery.css';

import galleryActiveIcon from 'assets/icons/gallery-active.svg';
import galleryInactiveIcon from 'assets/icons/gallery-inactive.svg';

const GalleryWrapper = styled.div`
  width: 100%;

  .image-gallery-thumbnail {
    width: auto;
  }
  .image-gallery-thumbnail {
    border-color: transparent !important;
    cursor: pointer;
  }
`;

const StyledHeading = styled(Heading)`
  text-align: center;
  color: ${({ theme }) => theme.colors.pink};
  margin-bottom: 6px;
`;

const Filters = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  margin-top: 6px;
  margin-bottom: 40px;
`;

const Filter = styled(Heading.withComponent('button'))`
  display: inline-flex;
  align-items: center;

  cursor: pointer;
  background: none;
  font-size: 16px;
  margin: 0 6px;
  padding: 4px;

  transition: color 200ms ease;

  ${({ active }) =>
    active &&
    css`
      color: ${({ theme }) => theme.colors.pink};
    `}

  &:hover {
    color: ${({ theme }) => theme.colors.pink100};
  }
`;

const ImageGalleryWrapper = styled.div`
  width: 100%;
  ${({ vertical }) =>
    vertical &&
    css`
      max-width: 600px;
      margin: 0 auto;
      @media (max-width: 768px) {
        max-width: 400px;
      }
    `}
`;

const Gallery = ({ images }) => {
  const isImages = images.some(({ type }) => type === 'images');
  const isOutside = images.some(({ type }) => type === 'outside');
  const isVisuals = images.some(({ type }) => type === 'visuals');
  const isInside = images.some(({ type }) => type === 'inside');
  const isProgress = images.some(({ type }) => type === 'progress');

  const firstVisibleIndex = [
    isImages,
    isOutside,
    isVisuals,
    isInside,
  ].findIndex(el => el === true);

  const matchesXS = useMedia('(max-width: 500px)');
  const matchesMD = useMedia('(max-width: 768px)');
  const [filters, setFilters] = useState({
    images: firstVisibleIndex === 0,
    outside: firstVisibleIndex === 1,
    visuals: firstVisibleIndex === 2,
    inside: firstVisibleIndex === 3,
    progress: firstVisibleIndex === 4,
  });

  const chooseFilter = property => {
    const newFilters = { ...filters };
    for (const prop in newFilters) {
      newFilters[prop] = prop == property;
    }
    setFilters(newFilters);
  };

  const galleryOptions = {
    showFullscreenButton: false,
    showPlayButton: false,
    showNav: false,
    infinite: true,

    renderItem: item => (
      <LazyImage
        key={item.original}
        src={item.original}
        alt={item.alt}
        cover="cover"
        height={
          matchesXS
            ? filters.visuals
              ? 400
              : 250
            : matchesMD
            ? 400
            : filters.visuals
            ? 700
            : 550
        }
      />
    ),

    renderThumbInner: item => (
      <LazyImage
        key={item.thumbnail}
        src={item.thumbnail}
        alt={item.alt}
        cover="cover"
        width={150}
        height={80}
      />
    ),
  };

  const galleryImages = images
    .filter(el => filters[el.type])
    .map(el => {
      if (el.thumbnail) {
        return el;
      }

      return {
        ...el,
        thumbnail: el.original,
      };
    });

  return (
    <GalleryWrapper>
      <StyledHeading>Galeria</StyledHeading>
      <Filters>
        {isImages && (
          <Filter
            active={filters.images}
            onClick={() => chooseFilter('images')}
          >
            <Icon
              size="32"
              src={filters.images ? galleryActiveIcon : galleryInactiveIcon}
            />
            Zdjęcia
          </Filter>
        )}
        {isOutside && (
          <Filter
            active={filters.outside}
            onClick={() => chooseFilter('outside')}
          >
            <Icon
              size="32"
              src={filters.outside ? galleryActiveIcon : galleryInactiveIcon}
            />
            Okolica
          </Filter>
        )}
        {isVisuals && (
          <Filter
            active={filters.visuals}
            onClick={() => chooseFilter('visuals')}
          >
            <Icon
              size="32"
              src={filters.visuals ? galleryActiveIcon : galleryInactiveIcon}
            />
            Wizualizacje
          </Filter>
        )}
        {isInside && (
          <Filter
            active={filters.inside}
            onClick={() => chooseFilter('inside')}
          >
            <Icon
              size="32"
              src={filters.inside ? galleryActiveIcon : galleryInactiveIcon}
            />
            Wnętrza
          </Filter>
        )}
        {isProgress && (
          <Filter
            active={filters.inside}
            onClick={() => chooseFilter('progress')}
          >
            <Icon
              size="32"
              src={filters.progress ? galleryActiveIcon : galleryInactiveIcon}
            />
            Relacje z budowy
          </Filter>
        )}
      </Filters>
      <ImageGalleryWrapper vertical={filters.visuals}>
        <ImageGallery {...galleryOptions} items={galleryImages} />
      </ImageGalleryWrapper>
    </GalleryWrapper>
  );
};

Gallery.propTypes = {
  images: PropTypes.array.isRequired,
};

export default Gallery;
