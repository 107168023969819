import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from 'components/shared/Heading';
import ContentWrapper from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Text from 'components/shared/Text';
import AskAboutFlat from 'components/AskAboutFlat/AskAboutFlat';
import ScrollableSection from 'react-update-url-on-scroll';
import clipBackground from 'assets/images/about3BG.png';
import { NavigationContext } from 'contexts/NavigationContext';

const Wrapper = styled.div`
  margin-top: -250px;
`;

const CenterWrapper = styled.section`
  position: relative;
  z-index: 4;
  padding: 0 0 350px;

  ${({ theme }) => theme.mq.xl} {
    padding: 200px 0 150px;
  }

  &:before {
    content: '';
    position: absolute;
    top: 5%;
    bottom: 0%;
    right: 0;
    width: 100%;
    z-index: 1;
    background: ${({ theme }) => theme.colors.dark100};
    background-image: url(${clipBackground});
    background-position: bottom;
    background-size: contain;
    background-repeat: no-repeat;

    clip-path: polygon(0 2%, 100% 15%, 100% 80%, 0% 91%);

    ${({ theme }) => theme.mq.s} {
      clip-path: polygon(0 3%, 100% 20%, 100% 79%, 0% 89%);
      top: 0;
      bottom: -5%;
    }

    ${({ theme }) => theme.mq.lg} {
      clip-path: polygon(0 3%, 100% 30%, 100% 73%, 0% 100%);
    }

    ${({ theme }) => theme.mq.xl} {
      top: -5%;
      clip-path: polygon(0 -10%, 95% 44%, 100% 70%, 0% 109%);
    }

    @media (min-width: 1600px) {
      clip-path: polygon(0 -10%, 105% 44%, 100% 68%, 0% 105%);
    }
  }
`;

const CenterInnerWrapper = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const InnerWrapperContent = styled.div`
  display: flex;
  flex-direction: column;

  ${({ theme }) => theme.mq.xl} {
    padding-left: 30px;
  }
`;

const MapWrapper = styled.div`
  position: relative;
  margin-top: -320px;
  ${({ theme }) => theme.mq.s} {
    margin-top: -400px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-top: -240px;
  }
`;

const CenterImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 300px;
  margin-left: auto;
  order: -1;
  margin-bottom: -75px;

  ${({ theme }) => theme.mq.xs} {
    margin-top: 50px;
    margin-bottom: -100px;
  }

  ${({ theme }) => theme.mq.s} {
    margin-top: 0;
    margin-bottom: -160px;
    max-width: 500px;
  }

  ${({ theme }) => theme.mq.md} {
    margin-bottom: -230px;
  }

  ${({ theme }) => theme.mq.lg} {
    margin-bottom: -300px;
    max-width: 600px;
  }

  ${({ theme }) => theme.mq.xl} {
    order: 1;
    margin-right: -20px;
    padding-left: 80px;
    max-width: 100%;
    margin-bottom: -125px;
  }
`;

const MapImageWrapper = styled.div`
  position: absolute;
  z-index: 5;
  left: 0;
  top: -5%;

  width: 100%;

  max-width: 250px;

  ${({ theme }) => theme.mq.s} {
    top: 10%;
    width: 40vw;
    max-width: 800px;
    height: auto;
  }

  ${({ theme }) => theme.mq.xl} {
    top: 7%;
  }

  ${({ theme }) => theme.mq.xxl} {
    top: 5%;
  }
`;

const Content = styled.article`
  display: flex;
  flex-direction: column;
  padding-top: 20px;

  ${({ theme }) => theme.mq.md} {
    padding-left: 8%;
  }

  ${({ theme }) => theme.mq.xl} {
    padding-left: 0;
    padding-top: 35px;
  }

  @media (min-width: 1600px) {
    padding-left: 5vw;
  }
`;

const Description = styled.div`
  padding-top: 20px;
  width: 100%;
  max-width: 580px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mq.s} {
    padding-top: 35px;
    padding-left: 0px;
    padding-right: 0px;
  }

  ${({ theme }) => theme.mq.xl} {
    width: 580px;
    min-width: 580px;
  }

  ${Text} {
    color: ${({ theme }) => theme.colors.white};
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.white};
  text-align: center;
  span {
    line-height: 0rem !important;
  }

  span::after {
    content: '';
    font-size: 0;
    display: block;
    line-height: 0;
  }

  ${({ theme }) => theme.mq.s} {
    text-align: left;

    span {
      line-height: 3.6rem;
    }
    span::after {
      content: none;
    }
  }
`;

const Map = styled.div`
  position: relative;
`;

const MapImage = styled(LazyImage)`
  height: 500px;

  ${({ theme }) => theme.mq.s} {
    height: auto;
  }

  &::after {
    box-shadow: inset 0px -100px 50px -10px #fff;
    content: '';
    display: block;
    height: 100%;
    position: absolute;
    top: 0;
    width: 100%;
  }
`;

const ScrollAnchor = styled.span`
  position: absolute;
  top: 15%;
  left: 0;

  ${({ theme }) => theme.mq.xl} {
    top: 40%;
  }
`;

const About3 = ({
  heading,
  description,
  image1,
  image1Alt,
  image2,
  image2Alt,
  mapImage,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    typeof window !== 'undefined' && (
      <Wrapper>
        <ScrollableSection name={`${initialSearchParam}#o-inwestycji `}>
          <CenterWrapper>
            <CenterInnerWrapper>
              <InnerWrapperContent id="o-inwestycji">
                <AskAboutFlat />
                <ContentWrapper>
                  <Content>
                    <StyledHeading
                      secondary
                      dangerouslySetInnerHTML={{ __html: heading }}
                    />
                    <Description>
                      <Text dangerouslySetInnerHTML={{ __html: description }} />
                    </Description>
                  </Content>
                </ContentWrapper>
              </InnerWrapperContent>
              <CenterImageWrapper>
                <LazyImage src={image1} alt={image1Alt} cover />
              </CenterImageWrapper>
            </CenterInnerWrapper>
          </CenterWrapper>
        </ScrollableSection>
        <ScrollableSection name={`${initialSearchParam}#lokalizacja`}>
          <MapWrapper>
            <MapImageWrapper>
              <LazyImage src={image2} alt={image2Alt} cover />
            </MapImageWrapper>
            <Map>
              <ScrollAnchor id="lokalizacja" />
              <MapImage src={mapImage} alt="Map" cover />
            </Map>
          </MapWrapper>
        </ScrollableSection>
      </Wrapper>
    )
  );
};

About3.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image1: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  image1Alt: PropTypes.string.isRequired,
  image2: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  mapImage: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  image2Alt: PropTypes.string.isRequired,
};

export default About3;
