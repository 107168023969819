import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import Heading from 'components/shared/Heading';
import ContentWrapper from 'components/shared/Content';
import LazyImage from 'components/shared/LazyImage';
import Text from 'components/shared/Text';
import Button from 'components/shared/Button';
import Gallery from 'components/Gallery/Gallery';
import clipBackground from 'assets/images/designBG.png';
import clipBackgroundMobile from 'assets/images/designBGMobile.png';
import deerPDF from 'assets/Ulotka Deer Design Matexi.pdf';
import ScrollableSection from 'react-update-url-on-scroll';
import { NavigationContext } from 'contexts/NavigationContext';

const Wrapper = styled.section`
  position: relative;
  margin-top: 50px;
  &:before {
    content: '';
    position: absolute;
    top: 10%;
    bottom: -25%;
    right: 0;
    width: 100%;
    z-index: 1;
    background: ${({ theme }) => theme.colors.dark100};
    background-image: url(${clipBackgroundMobile});
    background-position: center;
    background-size: contain;
    background-repeat: no-repeat;

    clip-path: polygon(0 20%, 100% 0, 100% 90%, 0 80%);
    ${({ theme }) => theme.mq.s} {
      bottom: -30%;
      background-image: url(${clipBackground});
      clip-path: polygon(0 20%, 100% 0, 100% 90%, 0 73%);
    }
    ${({ theme }) => theme.mq.xl} {
      clip-path: polygon(0 20%, 100% 0, 100% 90%, 0 56%);
    }
  }
`;

const AboutWrapper = styled.div`
  position: relative;
  z-index: 4;
  ${({ theme }) => theme.mq.s} {
    margin-top: 100px;
  }
`;

const InnerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  z-index: 2;

  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 500px;
  margin-bottom: -50px;
  ${({ theme }) => theme.mq.xl} {
    margin-bottom: -220px;
  }
  ${({ theme }) => theme.mq.xl} {
    margin-bottom: 0;
    padding-right: 100px;
    max-width: 100%;
  }
`;

const GalleryWrapper = styled.div`
  position: relative;
  z-index: 5;
  width: 100%;
  height: 100%;
  max-width: 1000px;
  padding: 0 10px;
  margin: 0 auto;
  ${({ theme }) => theme.mq.md} {
    padding: 0 20px;
  }
  ${({ theme }) => theme.mq.lg} {
    padding: 0 30px;
    // max-width: 1100px;
  }
`;

const Content = styled.article`
  display: flex;
  flex-direction: column;
  margin-left: auto;
  max-width: 600px;

  ${({ theme }) => theme.mq.xl} {
    max-width: 750px;
    margin-left: initial;
  }
`;

const Description = styled.div`
  padding-top: 10px;
  width: 100%;
  max-width: 580px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mq.s} {
    padding-top: 20px;
    padding-left: 0px;
    padding-right: 0px;
  }
  color: ${({ theme }) => theme.colors.white};
`;

const Logo = styled.figure`
  display: block;
  width: 250px;
  max-width: 100%;
  margin: 10px auto;
  ${({ theme }) => theme.mq.s} {
    margin: 10px 0;
  }
  ${({ theme }) => theme.mq.md} {
    width: 300px;
  }
`;

const StyledHeading = styled(Heading)`
  color: ${({ theme }) => theme.colors.pink};
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    text-align: left;
  }
`;

const StyledText = styled(Text)`
  color: inherit;
`;

const List = styled.ul`
  margin-top: 20px;
`;

const ListItem = styled(Text.withComponent('li'))`
  color: inherit;
  margin: 4px 0;
  font-weight: ${({ theme }) => theme.semiBold};
  a {
    color: ${({ theme }) => theme.colors.pink};
    transition: color ease 200ms;
    text-decoration: none;

    &:hover {
      color: ${({ theme }) => theme.colors.pink100};
    }
  }
`;

const PhoneButton = styled(Button.withComponent('a'))`
  width: 90%;
  max-width: initial;
  white-space: nowrap;
  font-size: 1.2rem;
  max-width: 250px;

  margin: 16px auto;
  padding: 8px 10px;

  ${({ theme }) => theme.mq.s} {
    font-size: 1.5rem;
    max-width: none;
    width: auto;
    float: left;
    margin: 16px 0;
    padding: 10px 12px;
  }
`;

const About5 = ({
  image,
  imageAlt,
  logo,
  logoAlt,
  heading,
  description,
  basicServicesList,
  additionalServicesList,
  team,
}) => {
  const { initialSearchParam } = useContext(NavigationContext);

  return (
    typeof window !== 'undefined' && (
      <ScrollableSection name={`${initialSearchParam}#wykonczenia`}>
        <Wrapper id="wykonczenia">
          <AboutWrapper>
            {' '}
            <ContentWrapper>
              <Content>
                <StyledHeading
                  secondary
                  dangerouslySetInnerHTML={{ __html: heading }}
                />
              </Content>
            </ContentWrapper>
          </AboutWrapper>
        </Wrapper>
      </ScrollableSection>
    )
  );
};

About5.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
  logo: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  logoAlt: PropTypes.string.isRequired,
  basicServicesList: PropTypes.array.isRequired,
  additionalServicesList: PropTypes.array.isRequired,
  team: PropTypes.array.isRequired,
};

About5.defaultProps = {};

export default About5;
