import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import LazyLoad from 'react-lazyload';
import styled from 'styled-components';
import Heading from 'components/shared/Heading';
import ContentWrapper from 'components/shared/Content';
import Text from 'components/shared/Text';
import useIsClipPathSupported from 'hooks/useIsClipPathSupported';
import LazyImage from 'components/shared/LazyImage';
import contentBackground from 'assets/images/about1BG.png';
import contentMobileBackground from 'assets/images/about1BGMobile.png';
import linesBackground from 'assets/images/lines.png';
import AnimatedNumber from 'react-animated-number';
import ScrollableSection from 'react-update-url-on-scroll';
import { NavigationContext } from 'contexts/NavigationContext';

const Wrapper = styled.div`
  margin-top: -1px;
  background-image: url(${contentMobileBackground});
  background-position: bottom center;
  background-size: contain;
  background-repeat: no-repeat;

  ${({ theme }) => theme.mq.s} {
    background-image: url(${contentBackground});
    background-position: left center;
    background-size: contain;
  }
`;

const CenterWrapper = styled.section`
  position: relative;
  z-index: 4;

  &:after {
    content: '';
    position: absolute;
    top: -95%;
    bottom: 0;
    right: 0;
    width: 130%;
    z-index: 1;
    background-image: url(${linesBackground});
    background-position: right center;
    background-size: contain;
    background-repeat: no-repeat;

    ${({ theme }) => theme.mq.s} {
      width: 77%;
      top: -108%;
      clip-path: polygon(0 0, 100% 0, 100% 100%, 0 60%);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    z-index: 1;
    background: ${({ theme }) => theme.colors.dark100};

    clip-path: polygon(0 0, 100% 0, 100% 54%, 0 25%);
    ${({ theme }) => theme.mq.s} {
      clip-path: polygon(100% 0, 0 0, 100% 75%);
    }
    ${({ theme }) => theme.mq.xl} {
      padding: 100px 0 0;
    }
  }
`;

const InnerWrapper = styled.div`
  position: relative;
  z-index: 4;
  display: flex;
  flex-direction: column;
  ${({ theme }) => theme.mq.xl} {
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }
`;

const ImageWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 550px;
  margin-left: auto;
  margin-bottom: -100px;
  order: -1;

  ${({ theme }) => theme.mq.s} {
    margin-bottom: -300px;
  }

  ${({ theme }) => theme.mq.xl} {
    order: 1;
    margin-bottom: 0px;
    margin-right: -20px;
    padding-left: 10px;
    max-width: 100%;
  }
`;

const Content = styled.article`
  display: flex;
  flex-direction: column;

  @media (min-width: 1600px) {
    padding-left: 12vw;
  }
  @media (max-width: 360px) {
    padding-top: 20px;
  }
`;

const Description = styled.div`
  padding-top: 20px;
  width: 100%;
  max-width: 580px;
  padding-left: 20px;
  padding-right: 20px;
  ${({ theme }) => theme.mq.s} {
    padding-top: 40px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Stats = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 3rem;
  max-width: 100%;
  padding-left: 10px;
  padding-right: 10px;
  ${({ theme }) => theme.mq.s} {
    max-width: 580px;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const Stat = styled.div``;

const StatNumber = styled(Heading)`
  color: ${({ theme }) => theme.colors.pink};
  margin-bottom: -4px;
`;

const StatLabel = styled(Text)`
  line-height: 1.8rem;
  font-size: 1.6rem;
  font-weight: ${({ theme }) => theme.bold};
  font-family: ${({ theme }) => theme.fonts.subFont};
  text-transform: uppercase;
  text-align: left;
  @media (max-width: 360px) {
    font-size: 1.4rem;
  }
`;

const StyledHeading = styled(Heading)`
  padding-left: 1rem;
  text-align: center;
  ${({ theme }) => theme.mq.s} {
    text-align: left;
  }
`;

const About1 = ({ heading, description, stats, image, imageAlt }) => {
  const { initialSearchParam } = useContext(NavigationContext);

  const clipPath = useIsClipPathSupported();

  const statsDOM = [...stats].splice(0, 3).map(el => (
    <Stat key={el.title}>
      <StatNumber>
        <LazyLoad height={60} offset={20} once>
          <AnimatedNumber
            duration={1500}
            value={el.value}
            style={{
              fontSize: 'inherit',
              lineHeight: 'inherit',
            }}
            formatValue={n => parseInt(n)}
          />{' '}
        </LazyLoad>
      </StatNumber>
      <StatLabel dangerouslySetInnerHTML={{ __html: el.title }} />
    </Stat>
  ));

  return (
    typeof window !== 'undefined' && (
      <ScrollableSection name={`${initialSearchParam}#o-inwestycji`}>
        <Wrapper clipPath={clipPath}>
          <CenterWrapper>
            <InnerWrapper>
              <ContentWrapper>
                <Content>
                  <StyledHeading
                    secondary
                    dangerouslySetInnerHTML={{ __html: heading }}
                  />
                  <Description>
                    <Text dangerouslySetInnerHTML={{ __html: description }} />
                  </Description>
                  <Stats>{statsDOM}</Stats>
                </Content>
              </ContentWrapper>
              <ImageWrapper>
                <LazyImage src={image} alt={imageAlt} cover />
              </ImageWrapper>
            </InnerWrapper>
          </CenterWrapper>
          {/* <ContentWrapper>
        <Content>
        <StyledHeading
        secondary
        dangerouslySetInnerHTML={{ __html: heading }}
        />
        <Description>
        <Text dangerouslySetInnerHTML={{ __html: description }} />
        </Description>
        </Content>
        </ContentWrapper>
        <ImageWrapper>
        <LazyImage src={image} alt={imageAlt} cover />
      </ImageWrapper> */}
        </Wrapper>
      </ScrollableSection>
    )
  );
};

About1.propTypes = {
  heading: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  stats: PropTypes.array,
  image: PropTypes.objectOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  ).isRequired,
  imageAlt: PropTypes.string.isRequired,
};

About1.defaultProps = {
  stats: [],
};

export default About1;
