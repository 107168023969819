import React from 'react';
import PropTypes from 'prop-types';
import styled, { css } from 'styled-components';
import Button from 'components/shared/Button';

const Wrapper = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 500px;

  & > button {
    max-width: 400px;
  }
`;

const Wrapper2 = styled.aside`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  width: 100%;
  height: 540px;
`;

const Heading = styled.h6`
  font-size: 2rem;
  font-weight: 600;
  color: ${({ theme }) => theme.colors.green};
  text-transform: uppercase;
  text-align: center;
  line-height: 1.35;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2.5rem;
  }
`;

const Heading2 = styled.h6`
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 5rem;
  color: #1a2732;
  text-transform: uppercase;
  text-align: center;
  line-height: 1.35;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2.5rem;
  }
`;

const Message = styled.p`
  font-size: 1.7rem;
  margin: 7rem 0 8rem;
  font-weight: 500;
  color: #000000;
  text-align: center;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
    text-align: left;
  }
`;

const Message2 = styled.p`
  font-size: 1.7rem;
  margin-bottom: 12rem;
  font-weight: 500;
  color: #000000;
  text-align: center;

  ${({ whiteText }) =>
    whiteText &&
    css`
      color: ${({ theme }) => theme.colors.white};
    `}

  ${({ theme }) => theme.mq.lg} {
    font-size: 2rem;
  }
`;

const StyledButton = styled(Button)`
  ${({ flatForm }) =>
    flatForm &&
    css`
      background: #e61260;
      &:hover {
        background: ${({ theme }) => theme.colors.white};
        color: ${({ theme }) => theme.colors.pink};
      }
    `}
`;

const ReserveMessage = styled.p`
  margin: -7rem 0px 3rem;
  text-align: center;
  font-size: 15px;
  color: #000000;
  line-height: 115%;

  ${({ theme }) => theme.mq.lg} {
    font-size: 16px;
  }
`;

const SuccessInfo = ({
  contactForm,
  reserveMessage,
  flatForm,
  whiteText,
  onClick,
}) => {
  return contactForm ? (
    <Wrapper>
      <Heading whiteText={whiteText}>
        Zapytanie zostało wysłane pomyślnie
      </Heading>
      <Message whiteText={whiteText}>
        Nasz doradca skontaktuje się z Tobą.
      </Message>
      <StyledButton type="button" submit onClick={onClick}>
        Ok
      </StyledButton>
    </Wrapper>
  ) : (
    <Wrapper2>
      <Heading2 whiteText={whiteText}>
        Zapytanie zostało wysłane pomyślnie
      </Heading2>
      <Message2 whiteText={whiteText}>
        Nasz doradca skontaktuje się z Tobą.
      </Message2>
      {reserveMessage && (
        <ReserveMessage>
          W celu potwierdzenia rezerwacji prosimy o kliknięcie w link, który
          przesłaliśmy na podany w formularzu adres e-mail. Link będzie aktywny
          przez 15 minut, po tym czasie możliwość dokonania rezerwacji zostanie
          anulowana. Rezerwacja będzie aktywna maksymalnie przez 3 dni robocze.
        </ReserveMessage>
      )}
      <StyledButton type="button" submit flatForm={flatForm} onClick={onClick}>
        Ok
      </StyledButton>
    </Wrapper2>
  );
};

SuccessInfo.propTypes = {
  contactForm: PropTypes.bool,
  reserveMessage: PropTypes.bool,
  whiteText: PropTypes.bool,
  flatForm: PropTypes.bool,
  onClick: PropTypes.func.isRequired,
};

SuccessInfo.defaultProps = {
  contactForm: false,
  reserveMessage: false,
  whiteText: false,
  flatForm: false,
};

export default SuccessInfo;
